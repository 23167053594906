<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <div class="flex">
                    <h5><font-awesome-icon icon="building" /> Unidade de Atendimento</h5>
                    <btn-refresh @click="obterUnidade()"></btn-refresh>
                </div>
                <div class="mb-4">
                    <detalhe titulo="Cód. Unid. Atendimento" :size="sizeLabel">{{ unidade?.codUnidadeAtendimento }}</detalhe>
                    <detalhe titulo="Unidade de Atendimento" :size="sizeLabel">{{ unidade?.unidadeAtendimento }}</detalhe>
                    <detalhe titulo="Unidade Organizacional" :size="sizeLabel">{{ unidade?.unidadeOrganizacional }}</detalhe>
                    <detalhe titulo="Projeto" :size="sizeLabel">{{ unidade?.projeto }}</detalhe>
                    <detalhe titulo="Ação" :size="sizeLabel">{{ unidade?.acao }}</detalhe>
                    <detalhe titulo="Responsável" :size="sizeLabel">{{ $toCpf(unidade?.cpfResponsavel) }} - {{ unidade?.responsavel }}</detalhe>
                    <detalhe titulo="Instrumento" :size="sizeLabel">{{ unidade?.instrumento }}</detalhe>
                    <detalhe titulo="Tipo da Realização" :size="sizeLabel">{{ unidade?.tipoRealizacao }}</detalhe>
                    <detalhe titulo="Nome da Realização" :size="sizeLabel">{{ unidade?.nomeRealizacao }}</detalhe>
                    <detalhe titulo="Carga Horária" :size="sizeLabel">{{ unidade?.cargaHoraria }} minutos</detalhe>
                    <detalhe titulo="Lista de Emails Integração" :size="sizeLabel"
                        ><span v-if="unidade && unidade.listaEmailsIntegracao"
                            ><Chip v-for="email in unidade.listaEmailsIntegracao" :key="email" class="mr-1">{{ email }}</Chip></span
                        ></detalhe
                    >
                    <detalhe titulo="Status" :size="sizeLabel">
                        <status :status="unidade?.statusDescr"></status>
                    </detalhe>
                </div>
                <btn-inserir></btn-inserir>
                <btn-atualizar :unidade="unidade"></btn-atualizar>
                <btn-voltar :route="{ name: 'Expressos_Unidades' }"></btn-voltar>
            </div>
        </div>
    </div>
    <perfis></perfis>
</template>

<script>
import UnidadesServices from './services';
import BtnInserir from './BtnInserir';
import BtnAtualizar from './BtnAtualizar';

export default {
    components: {
        BtnInserir,
        BtnAtualizar,
    },

    data() {
        return {
            unidade: null,
            sizeLabel: '170',
        };
    },

    methods: {
        obterUnidade() {
            this.$store.dispatch('addRequest');
            UnidadesServices.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.unidade = response.data;
                } else {
                    this.unidade = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    mounted() {
        this.obterUnidade();
    },
};
</script>
